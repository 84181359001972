import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const LicensePage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO noindex={true} title="Package" />
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h3 id="dynamic-styles">Thank you for purchase! 💚</h3>
          <p>
            We hope you'll enjoy yours mockups! Feel free to contact us by this
            email: hello@palapa.tech if you'll have any questions
          </p>
          <p>Have a nice day! :)</p>
          <div>
            <a
              href="https://drive.google.com/file/d/1ewPrBiU4DVci4QwvQ8koerMGrZGwT6J5/view"
              className="button primary large"
              target="_blank"
            >
              Download your package (61 MB)
            </a>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <LicensePage location={props.location} data={data} {...props} />
    )}
  />
)
